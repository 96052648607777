<template>
  <div class="page-accounting">
    <lead-center-navigation />
    <div class="agGrid-page container">
      <base-header>
        <lead-center-date />
        <lead-center-management-addon />
      </base-header>
      <lead-center-charts-activities :data="chartsData" v-if="showChartsLeadCenter" />
      <base-ag-grid
        :columnDefs="columnDefs"
        :datasource="datasource"
        :rowSelection="rowSelection"
        :cellValueChanged="cellValueChanged"
        :onSelectionChanged="onSelectionChanged"
        :enableRangeSelection="true"
        :getContextMenuItems="contextMenu"
      />
    </div>
  </div>
</template>

<script>
// Components
import BaseAgGrid from '@/agGridV2/components/base.component.vue'
import LeadCenterChartsActivities from '@/agGridV2/components/lead-center-charts-activities.component.vue'
import LeadCenterDate from '@/agGridV2/components/lead-center-date.component.vue'
// API Init
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import SimpleHelper from '@/agGridV2/helpers/simple.helper'
import LeadCenterNavigation from '@/components/LeadCenterNavigation.vue'
import BaseHeader from '@/agGridV2/components/header/header.component.vue'
import LeadCenterManagementAddon from '@/agGridV2/components/header/lead-center-management.component.vue'
import ManualQualificationPopup from '@/agGridV2/components/manual-qualification-popup.component.vue'
import { showSnackbar } from "@/services";
import { showTooltip } from "@codemirror/view";
import { mapGetters } from 'vuex'
import SimpleSelectRenderer from '@/agGridV2/renderers/simple-select.renderer.vue'
// Renderers
import AiQualifiedCellRenderer from '@/agGridV2/renderers/ai-qulified.renderer.vue'

export default {
  components: {
    LeadCenterDate,
    LeadCenterChartsActivities,
    LeadCenterManagementAddon,
    BaseHeader,
    LeadCenterNavigation,
    BaseAgGrid,
    // eslint-disable-next-line vue/no-unused-components
    SimpleSelectRenderer,
    // eslint-disable-next-line vue/no-unused-components
    AiQualifiedCellRenderer
  },
  data() {
    return {
      datasource: null,
      columnDefs: null,
      customHtml: null,
      cellValueChanged: null,
      onSelectionChanged: null,
      rowSelection: 'multiple',
      chartsData: null,
      gridApi: null,
      contextMenu: (params) => {
        return  [
          'cut',
          'copy',
          'copyWithHeaders',
          'copyWithGroupHeaders',
          'paste',
          'separator',
          'export',
          'separator',
          {
            name: "Manual qualification",
            action: () => {
              this.$modal.show(
                ManualQualificationPopup,
                {
                  params
                }, { width: '90%' }
              )
            }
          },
        ]
      }
    }
  },
  computed: {
    showChartsLeadCenter() {
      return this.getShowChartsLeadCenter()
    },
    platforms() {
      return this.getPlatforms()
    }
  },
  methods: {
    ...mapGetters([
      'getShowChartsLeadCenter',
      'getPlatforms'
    ]),
  },
  async mounted() {
    const filters = await MtApi.getActivitiesFilters({
      platform_id: this.$store.getters.getSelectedPlatform._id,
      fields: ['platform', 'type', 'automate_data.location', 'automate_data.service', 'automate_data.medium', 'automate_data.ai_qualified']
    })
    this.columnDefs = [
      {
        field: '_id',
        headerName: 'ID',
        filter: false
      },
      {
        field: 'created_date',
        headerName: 'Created Date',
        filter: 'agDateColumnFilter'
      },
      {
        field: 'platform',
        headerName: 'Platform',
        cellRenderer: (params) => {
          const platform_id = params.getValue()
          const platform_object = this.platforms.find((p) => p._id === platform_id)
          if (platform_object) {
            return `<a href="https://${platform_object.url}" target="_blank">${platform_object.url}</a>`
          }
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          comparator: (filterValue, cellValue) => {
            return cellValue == filterValue;
          },
          values: (params) => {
            const valuesArray = filters.platform.map(item => item.type);
            params.success(valuesArray);
          },
          cellRenderer: (params) => {
            const platforms = this.getPlatforms()
            const filter = filters.platform.find((f) => f.type === params.value)
            const platform = platforms.find((p) => p._id === params.value)
            if (platform) {
              if (filter) {
                return platform.url
              }
              return platform.url
            }
            if (filter && !params.value) {
              return `(Blanks)`
            }
            return params.value;
          }
        }
      },
      {
        field: 'type',
        headerName: 'Type',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: (params) => {
            params.success(SimpleHelper.getModifiedFiltersLabels(filters.type))
          }
        }
      },
      {
        field: 'tech_data.application',
        headerName: 'Application'
      },
      {
        field: 'automate_data.name',
        headerName: 'Name'
      },
      {
        field: 'automate_data.phone',
        headerName: 'Phone'
      },
      {
        field: 'automate_data.email',
        headerName: 'Email'
      },
      {
        field: 'automate_data.message',
        headerName: 'Message',
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          maxLength: 100,
          rows: 10,
          cols: 50
        },
        editable: true
      },
      {
        field: 'automate_data.address',
        headerName: 'Address'
      },
      {
        field: 'automate_data.location',
        headerName: 'Location',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: (params) => {
            params.success(SimpleHelper.getModifiedFiltersLabels(filters['automate_data.location']))
          }
        }
      },
      {
        field: 'automate_data.service',
        headerName: 'Service',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: (params) => {
            params.success(SimpleHelper.getModifiedFiltersLabels(filters['automate_data.service']))
          }
        }
      },
      {
        field: 'automate_data.landing_page',
        headerName: 'Landing Page',
        cellRenderer: (params) => {
          const value = params.getValue()
          if (value) {
            return `<a href="${value}" target="_blank">${value}</a>`
          }
        }
      },
      {
        field: 'automate_data.source',
        headerName: 'Source'
      },
      {
        field: 'automate_data.medium',
        headerName: 'Medium',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: (params) => {
            params.success(SimpleHelper.getModifiedFiltersLabels(filters['automate_data.medium']))
          }
        }
      },
      {
        field: 'automate_data.gclid',
        headerName: 'Gclid'
      },
      {
        field: 'automate_data.campaign',
        headerName: 'Campaign'
      },
      {
        field: 'status',
        headerName: 'Technical Status'
      },
      {
        field: 'automate_data.ai_qualified',
        headerName: 'AI Qualified',
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          maxLength: 100,
          rows: 10,
          cols: 50
        },
        // editable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: (params) => {
            params.success(SimpleHelper.getModifiedFiltersLabels(filters['automate_data.ai_qualified']))
          }
        },
        cellRenderer: 'AiQualifiedCellRenderer'
      },
      {
        field: 'tech_data.form_id',
        headerName: 'Form ID'
      },
      {
        field: 'raw_data.upload_pictures_and/or_videos',
        headerName: 'Uploaded Pictures or Videos'
      },
      {
        field: 'raw_data',
        headerName: 'Raw Data',
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          maxLength: 100,
          rows: 10,
          cols: 50
        },
        editable: true,
        valueGetter: (params) => {
          return JSON.stringify(params.data.raw_data, null, 2)
        }
      },
      {
        field: 'tech_data',
        headerName: 'Tech Data',
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          maxLength: 100,
          rows: 10,
          cols: 50
        },
        editable: true,
        valueGetter: (params) => {
          return JSON.stringify(params.data.tech_data, null, 2)
        },
        filter: null
      },
      {
        field: 'cookie_data',
        headerName: 'Cookie Data',
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          maxLength: 100,
          rows: 10,
          cols: 50
        },
        editable: true,
        valueGetter: (params) => {
          return JSON.stringify(params.data.cookie_data, null, 2)
        },
        filter: null
      },
      {
        field: 'automate_data',
        headerName: 'Automate Data',
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          maxLength: 100,
          rows: 10,
          cols: 50
        },
        editable: true,
        valueGetter: (params) => {
          return JSON.stringify(params.data.automate_data, null, 2)
        },
        filter: null
      },
      {
        field: 'logs',
        headerName: 'Logs',
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          maxLength: 100,
          rows: 10,
          cols: 50
        },
        valueGetter: (params) => {
          if (params.data.logs && params.data.logs.length === 0) {
            return ''
          }
          return JSON.stringify(params.data.logs)
        },
        editable: true,
        filter: null
      },
      {
        field: 'copy_json',
        headerName: 'Copy JSON',
        cellRenderer: (params) => {
          const button = document.createElement('button')
          button.innerText = 'Copy JSON'
          button.className = 'button-outline-blue'
          button.addEventListener('click', () => {
            const jsonContent = JSON.stringify(params.data, null, 2)
            navigator.clipboard.writeText(jsonContent).then(() => {
              showSnackbar({
                text: 'JSON copied to clipboard!',
                mode: 'success'
              })
            }).catch(err => {
              console.error('Failed to copy JSON: ', err)
            });
          })
          return button
        }
      },
      {
        field: 'testing_tags',
        headerName: 'Testing Tags',
        editable: true
      },
      {
        field: 'manual_data.qualify',
        headerName: 'Qualify',
        editable: true,
        cellRenderer: 'SimpleSelectRenderer',
        cellRendererParams: {
          options: [
            {
              label: 'Qualified',
              value: 'Qualified'
            },
            {
              label: 'Disqualified',
              value: 'Disqualified'
            },
            {
              label: 'Unknown',
              value: 'Unknown'
            },
            {
              label: 'Repeat Customer',
              value: 'Repeat Customer'
            }
          ]
        },
      },
      {
        field: 'manual_data.service',
        headerName: 'Service',
        editable: true,
        cellRenderer: 'SimpleSelectRenderer',
        cellRendererParams: {
          platform_options_field: 'services',
          options: []
        },
      },
      {
        field: 'manual_data.location',
        headerName: 'Location',
        editable: true,
        cellRenderer: 'SimpleSelectRenderer',
        cellRendererParams: {
          platform_options_field: 'locations',
          options: []
        },
      },
    ]
    this.datasource = {
      getRows: (params) => {
        this.chartsData = []
        params.request['search'] = this.$store.getters.getSearchQuery(this.$route.name)
        params.request['platform'] = this.$store.getters.getSelectedPlatform
        if (this.$store.getters.getLeadCenterDate?.start?.code) {
          params.request['startDate'] = this.$store.getters.getLeadCenterDate.start.code
        }
        if (this.$store.getters.getLeadCenterDate?.end?.code) {
          params.request['endDate'] = this.$store.getters.getLeadCenterDate.end.code
        }
        MtApi.agGridActivities(params.request).then((response) => {
          this.$emitter.emit('grid_api', params.api)
          this.chartsData = response.charts
          this.gridApi = params.api
          this.$store.dispatch('setAgGridRowsCount', { page: this.$route.name, query: response.count })
          this.$emitter.emit('set_agGrid_rows_count', {})
          params.success({ rowData: response.rows, rowCount: response.count })
        })
      }
    }
    this.cellValueChanged = (params) => {
      if (['manual_data.location', 'manual_data.service', 'manual_data.qualify'].includes(params.colDef.field)) {
        MtApi.updateActivity(params.data._id, {
          event: 'update_activity',
          field: {
            name: params.colDef.field,
            value: params.value
          }
        })
      }
    }
    this.onSelectionChanged = (params) => {
      this.$emitter.emit('selected_rows_event', params)
    }
  }
}
</script>
